<template>
  <div>
    <div>
      <button class="centerBtn" @click="reginsEnterShow">注册企业</button>
    </div>
    <div class="mt20">
      <el-form :inline="true">
        <el-form-item>
          <el-input
            v-model="searchObj.companyName"
            placeholder="请输入企业名称"
            class="centerInput"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="searchObj.authAccount"
            placeholder="请输入授权账号"
            class="centerInput"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="searchObj.phone"
            placeholder="请输入联系电话"
            class="centerInput"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <button class="centerCzbtn" type="button" @click="getTableData(searchPage)">
            搜索
          </button>
        </el-form-item>
        <el-form-item>
          <button class="centerBtn resetbtn" type="button" @click="reset">
            重置
          </button>
        </el-form-item>
      </el-form>
    </div>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="total"
      @getAjax="getTableData"
      :loading="loading"
      :page="searchObj.page"
      :size="searchObj.size"
    />

    <!-- 注册企业弹框 -->

    <!-- destroy-on-close  消除开启弹框时自动验证form表单 -->
    <el-drawer
      :title="isAdd ? '注册企业' : '修改企业'"
      :visible.sync="registerQy"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      @close="drawClose"
      :wrapperClosable="isAdd"
      destroy-on-close
    >
      <!-- destroy-on-close -->
      <div class="demo-drawer__content">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :validate-on-rule-change="false"
        >
          <el-form-item
            label="企业安全码"
            :label-width="formLabelWidth"
            prop="securityCode"
          >
            <el-input
              v-model.trim="form.securityCode"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="企业名称"
            :label-width="formLabelWidth"
            prop="companyName"
          >
            <el-input
              v-model.trim="form.companyName"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
            <div class="example" v-show="hintShow">示例：东风悦享科技有限公司</div>
          </el-form-item>
          <el-form-item
            label="企业地址"
            :label-width="formLabelWidth"
            prop="companyAddress"
          >
            <el-input
              v-model.trim="form.companyAddress"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            >
            </el-input>
            <div class="example">示例：湖北省武汉市蔡甸区经开智造2045</div>
          </el-form-item>
          <el-form-item
            label="法人代表"
            :label-width="formLabelWidth"
            prop="legalPerson"
          >
            <el-input
              v-model.trim="form.legalPerson"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
            <div class="example" v-show="hintShow">示例：张三</div>
          </el-form-item>
          <el-form-item
            label="联系电话"
            :label-width="formLabelWidth"
            prop="phone"
          >
            <el-input
              v-model.trim="form.phone"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="经营范围"
            :label-width="formLabelWidth"
            prop="businessNature"
          >
            <el-input
              v-model.trim="form.businessNature"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="已购车型"
            :label-width="formLabelWidth"
            prop="carTypeIds"
          >
            <el-checkbox-group
              v-model="form.carTypeIds"
              @change="handleCheckedCitiesChange2"
            >
              <el-checkbox
                :label="item.id"
                name="type"
                v-for="(item, index) in carTypeList"
                :key="index"
              >
                {{ item.typeName }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            label="业务服务包"
            :label-width="formLabelWidth"
            prop="businessServiceIds"
          >
            <el-checkbox-group
              v-model="form.businessServiceIds"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox
                :label="item.id"
                name="type"
                v-for="(item, index) in serviceList"
                :key="index"
                >{{ item.serviceName }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            label="数据服务包"
            :label-width="formLabelWidth"
            prop="dataService"
          >
            <el-checkbox-group v-model="form.dataService">
              <el-checkbox
                :label="item.id"
                name="type"
                v-for="(item, index) in datetList"
                :disabled="dbShow"
                :key="index"
                >{{ item.serviceName }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item
            label="增值服务包"
            :label-width="formLabelWidth"
            prop="incrementServiceIds"
          >
            <el-checkbox-group v-model="form.incrementServiceIds">
              <el-checkbox
                v-for="(item, index) in incrementList"
                name="type"
                :label="item.id"
                :key="index"
                >{{ item.serviceName }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item
            label="授权账号"
            :label-width="formLabelWidth"
            prop="authAccount"
          >
            <el-input
              v-model="form.authAccount"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="授权菜单"
            :label-width="formLabelWidth"
            prop="menuIds"
          >
            <el-cascader
              @change="cascaderChange"
              v-model="form.menuIds"
              :options="menuList"
              ref="myCascader"
              class="centerCzSelInput"
              :props="props"
              collapse-tags
            >
            </el-cascader>
          </el-form-item>

          <el-form-item
            label="状态"
            :label-width="formLabelWidth"
            prop="authStatus"
          >
            <el-switch
              active-value="1"
              inactive-value="0"
              v-model="form.authStatus"
            ></el-switch>
          </el-form-item>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <el-button
          class="centerCzbtn mr20"
          @click="confirmCancellation"
          :loading="isLoading"
          >{{ isAdd ? "确认添加" : "确认修改" }}</el-button
        >
        <button class="centerBtn resetbtn" @click="registerQy = false">
          取消
        </button>
      </div>
    </el-drawer>

    <!-- 删除弹框 -->
    <el-dialog title="删除" :visible.sync="delDia" width="30%">
      <span>确认删除</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn mr20" @click="delConfig">确 定</button>
        <button class="centerBtn resetbtn" @click="delDia = false">
          取 消
        </button>
      </span>
    </el-dialog>

    <!-- 重置密码 -->
    <el-dialog title="重置密码" :visible.sync="resetPwd" width="30%">
      <span>确认重置密码</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn mr20" @click="resetPwdConfig">确 定</button>
        <button class="centerBtn resetbtn" @click="resetPwd = false">
          取 消
        </button>
      </span>
    </el-dialog>

    <!-- 设置权限 -->
    <el-drawer
      title="设置权限"
      :visible.sync="jurisdiction"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      destroy-on-close
    >
      <div class="demo-drawer__content">
        <el-tree
          ref="resourcesMenuRef"
          :setCheckedKeys="[]"
          default-expand-all
          :data="menuList"
          show-checkbox
          node-key="id"
          :props="defaultProps"
          @check="checkChange"
          :default-checked-keys="keyids"
          :check-strictly="checkSt"
          auto-expand-parent
        >
        </el-tree>
      </div>
      <div class="demo-drawer__footer">
        <button class="centerCzbtn mr20" @click="companyMenu">确认</button>
        <button class="centerBtn resetbtn" @click="jurisdiction = false">
          取消
        </button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import {
  _FindAllPageReg,
  _AddCompany,
  _CheckSecurityCode,
  _DeleteCompany,
  _UpdateUserCompanyAuth,
  _UpdateCompany,
  _UpdateUserCompanyPasswordCenter,
  _GetAllMenu,
  _UpdateCompanyMenu,
} from "@/api/enterpriseRegister/enterpriseRegister";
import {
  _CarTypeAll,
  _FindAllPage,
  _incrementsFindAll,
  _CheckUserCompanyPhone,
  _FindAllBusinessService,
  _FindAllDateService,
} from "@/api/selCar";

export default {
  components: {
    Table,
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      const reg = /^[1][1-9][0-9]{9}$/;

      if (value == "" || value == undefined || value == null) {
        callback();
      } else {
        if (!reg.test(value) && value != "") {
          callback(new Error("请输入正确的电话号码"));
        } else {
          callback();
        }
      }
    };
    var validateCa = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择授权菜单"));
      } else {
        callback();
      }
    };
    return {
      hintShow: true,// 展示提示
      loading: false,
      isLoading: false,
      registerQy: false,
      delDia: false,
      resetPwd: false,
      jurisdiction: false,
      dbShow: false,
      checkSt: false,
      formLabelWidth: "100px",
      carTypeList: [],
      rowData: {},
      jurisdictionIdList: [],
      serviceList: [],
      searchObj: {
        companyName: "",
        authAccount: "",
        phone: "",
        page: 1,
        size: 10,
      },
      searchPage:{
        page: 1,
        limit: 10,
      },
      defaultProps: {
        children: "children",
        label: "menuName",
      },
      menuList: [],
      // menuListIds: [
      //   {
      //     id: "431ede72641d4699853c62d284bc7545",
      //     parentId: "0",
      //     level: null,
      //     menuName: "园区12",
      //     code: "005",
      //     url: "/sass/part",
      //     icon: " el-icon-phone-outline",
      //     sort: 1,
      //     isMenu: 1,
      //     createUserId: null,
      //     createTime: "2021-08-28 13:59:10",
      //     updateUserId: null,
      //     updateTime: "2021-08-28 23:20:36",
      //     children: [
      //       {
      //         // "id": "d19e13e452fc4dafa58159da4fd78dc8",
      //         // "parentId": "431ede72641d4699853c62d284bc7545",
      //         // "level": null,
      //         // "menuName": "车辆实时数据2",
      //         // "code": "045",
      //         // "url": "/sass/shishi",
      //         // "icon": "el-icon-info",
      //         // "sort": 1,
      //         // "isMenu": 1,
      //         // "createUserId": null,
      //         // "createTime": "2021-08-28 14:16:39",
      //         // "updateUserId": null,
      //         // "updateTime": "2021-08-29 00:38:33",
      //         // "children": []
      //       },
      //     ],
      //   },
      //   {
      //     id: "45f451cc97ee4efcb890e211a9bcbbf5",
      //     parentId: "0",
      //     level: null,
      //     menuName: "菜单1",
      //     code: "codebs",
      //     url: "",
      //     icon: "",
      //     sort: null,
      //     isMenu: 1,
      //     createUserId: "c6543ce911c5473f89a7760ad1c6aad1",
      //     createTime: "2021-08-29 12:25:07",
      //     updateUserId: "7dcdfe50a78440efa7bb0b99fe59aa43",
      //     updateTime: "2021-08-29 16:26:06",
      //     children: [],
      //   },
      //   {
      //     id: "50586a99fff04002a8cd7bc97f518397",
      //     parentId: "0",
      //     level: null,
      //     menuName: "APP账号管理",
      //     code: "009",
      //     url: "/sass/app",
      //     icon: "el-icon-goods",
      //     sort: 6,
      //     isMenu: 1,
      //     createUserId: null,
      //     createTime: "2021-08-28 14:15:16",
      //     updateUserId: null,
      //     updateTime: "2021-08-28 17:27:01",
      //     children: [
      //       {
      //         id: "dae59bdaf2e84025ae58be91fb3ef76f",
      //         parentId: "50586a99fff04002a8cd7bc97f518397",
      //         level: null,
      //         menuName: "app3",
      //         code: "0123",
      //         url: "/center/app1",
      //         icon: "el-icon-star-off",
      //         sort: 1,
      //         isMenu: 1,
      //         createUserId: null,
      //         createTime: "2021-08-28 14:37:23",
      //         updateUserId: null,
      //         updateTime: "2021-08-28 14:52:28",
      //         children: [],
      //       },
      //     ],
      //   },
      //   {
      //     id: "bac70d1ea3e84492b642737975baccc8",
      //     parentId: "0",
      //     level: null,
      //     menuName: "菜单1",
      //     code: "code111",
      //     url: "/saa/cd",
      //     icon: "icon-lzj",
      //     sort: 1,
      //     isMenu: 1,
      //     createUserId: "7dcdfe50a78440efa7bb0b99fe59aa43",
      //     createTime: "2021-08-29 16:20:43",
      //     updateUserId: null,
      //     updateTime: null,
      //     children: [],
      //   },
      //   {
      //     id: "c5b647ded691428a9f44eef7282ee8d8",
      //     parentId: "0",
      //     level: null,
      //     menuName: "",
      //     code: "",
      //     url: "",
      //     icon: "",
      //     sort: null,
      //     isMenu: 1,
      //     createUserId: "7dcdfe50a78440efa7bb0b99fe59aa43",
      //     createTime: "2021-08-29 18:28:46",
      //     updateUserId: null,
      //     updateTime: null,
      //     children: [],
      //   },
      // ],
      total: 0,
      isAdd: false,
      incrementList: [],
      dtList: [],
      // checkStrictly:true,
      form: {
        securityCode: "", //企业安全码
        companyName: "", //企业名称
        companyAddress: "", //企业地址
        legalPerson: "", //法人代表
        phone: "", // 联系电话
        businessNature: "", // 经营范围
        carTypeIds: [], // 已购车型
        businessServiceIds: [], //业务服务包
        incrementServiceIds: [], // 增值服务包
        dataService: [], //数据服务包
        authAccount: "", // 授权账号
        menuIds: [], //授权菜单
        authStatus: "1", //状态
        parentId: "000000",
      },
      rules: {
        // securityCode: [
        //   {
        //     required: true,
        //     message: "请输入企业安全码",
        //     trigger: "blur",
        //   },
        // ],
        companyName: [
          {
            required: true,
            message: "请输入企业名称（示例：东风悦享科技有限公司）",
            trigger: "blur",
          },
        ],
        legalPerson: [
          {
            required: true,
            message: "请输入法人代表（示例：张三）",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "blur",
          },
          {
            required: true,
            validator: checkPhone,
            trigger: "blur",
          },
        ],
        businessNature: [
          {
            required: true,
            message: "请输入经营范围",
            trigger: "blur",
          },
        ],
        carTypeIds: [
          {
            required: true,
            message: "请选择已购车型",
            trigger: "change",
          },
        ],
        authAccount: [
          {
            required: true,
            message: "请输入授权账号",
            trigger: "blur",
          },
        ],
        menuIds: [
          {
            // required: true,
            // message: '请输入授权菜单',
            // trigger: 'change'
            required: true,
            validator: validateCa,
            trigger: "blur",
          },
        ],
        authStatus: [
          {
            required: true,
            message: "请选择状态",
            trigger: "change",
          },
        ],
      },
      tableData: [],
      treeData: [
        {
          id: 1,
          label: "根菜单",
          children: [
            {
              id: 2,
              label: "设备监控",
              children: [
                {
                  id: 7,
                  label: "大屏监控(/screen)",
                },
                {
                  id: 8,
                  label: "园区概览(/location)",
                },
                {
                  id: 9,
                  label: "车辆状态(/vehicle-control)",
                },
                {
                  id: 10,
                  label: "车辆线路关系(/relationship)",
                },
              ],
            },
            {
              id: 3,
              label: "微公交",
            },
            {
              id: 4,
              label: "SV-BUS",
            },
            {
              id: 5,
              label: "配送车(/sharingDelivery)",
            },
            {
              id: 6,
              label: "售卖车(/sharingSale)",
            },
          ],
        },
      ],
      columns: [
        {
          label: "状态",
          render: (h, params) => {
            return h("div", [
              h(
                "el-switch",
                {
                  on: {
                    change: (val) => {
                      params.row.authStatus = !params.row.authStatus;
                      this.changeStatus(
                        params.row.id,
                        params.row.authStatus ? 1 : 0
                      );
                    },
                  },
                  props: {
                    value: params.row.authStatus ? true : false,
                  },
                  scopedSlots: {
                    open: () => h("span", "开启"),
                    close: () => h("span", "停用"),
                  },
                },
                "编辑"
              ),
            ]);
          },
        },
        {
          label: "企业名称",
          prop: "companyName",
        },
        {
          label: "授权账号",
          prop: "authAccount",
        },
        {
          label: "法人代表",
          prop: "legalPerson",
        },
        {
          label: "联系电话",
          prop: "phone",
        },
        {
          label: "经营范围",
          prop: "businessNature",
        },
        {
          label: "操作",
          // fixed: "right",
          width: 300,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA mr20",
                  on: {
                    click: () => {
                      this.isAdd = false;
                      this.registerQy = true;
                      this.rowData = params.row;

                      this.form = {
                        securityCode: params.row.securityCode
                          ? params.row.securityCode
                          : "", //企业安全码
                        companyName: params.row.companyName
                          ? params.row.companyName
                          : "", //企业名称
                        companyAddress: params.row.companyAddress
                          ? params.row.companyAddress
                          : "", //企业地址
                        legalPerson: params.row.legalPerson
                          ? params.row.legalPerson
                          : "", //法人代表
                        phone: params.row.phone ? params.row.phone : "", // 联系电话
                        businessNature: params.row.businessNature
                          ? params.row.businessNature
                          : "", // 经营范围
                        carTypeIds: params.row.carTypeIds
                          ? params.row.carTypeIds.split(",")
                          : [], // 已购车型
                        businessServiceIds: params.row.businessServiceIds
                          ? params.row.businessServiceIds.split(",")
                          : [], //业务服务包
                        incrementServiceIds: params.row.incrementServiceIds
                          ? params.row.incrementServiceIds.split(",")
                          : [], // 增值服务包
                        dataService: params.row.dataService
                          ? params.row.dataService.split(",")
                          : [],
                        authAccount: params.row.authAccount
                          ? params.row.authAccount
                          : "", // 授权账号
                        menuIds: params.row.menuIds ? params.row.menuIds : [], //授权菜单
                        authStatus: params.row.authStatus
                          ? params.row.authStatus.toString()
                          : "", //状态
                        parentId: "000000",
                      };
                      let ids2 = "";
                      this.form.dataService.forEach((e) => {
                        let obj2 = {};
                        obj2 = this.menuList.find((item) => {
                          return item.id == e;
                        });
                        if (typeof obj2 != "undefined") {
                          obj2.children.forEach((chilIds) => {
                            ids2 += chilIds.id + ",";
                          });
                        }
                      });
                      if (params.row.carTypeIds.length > 0) {
                        this.dbShow = true;
                        this.form.menuIds = [
                          ...this.form.menuIds,
                          ...this.form.dataService,
                          ...ids2.split(","),
                          "9a96b1d58fd34029b1a2998dec1adcdf",
                          "a04b37e827c047bd9256f65ee908ea21",
                          "b869798c8c71475dad32d194a1f3fc46",
                        ];
                        console.log(this.form.menuIds);
                      } else {
                        this.dbShow = false;
                        this.form.dataService = [];
                      }
                    },
                  },
                },
                "编辑"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.rowData = params.row;

                      this.delDia = true;
                    },
                  },
                },
                "删除"
              ),
              h(
                "span",
                {
                  class: "operationA ml20",
                  on: {
                    click: () => {
                      var that = this;

                      // console.log(this.$refs.resourcesMenuRef);
                      //
                      // this.$nextTick(() => {
                      // 	that.$refs.©.setCheckedKeys([]);
                      // });
                      this.jurisdiction = true;
                      this.checkSt = true;
                      this.$nextTick(() => {
                        this.keyids = params.row.menuIds;
                        this.checkSt = false;
                      });

                      this.rowData = params.row;

                      this.jurisdictionIdList = params.row.menuIds;
                    },
                  },
                },
                "设置权限"
              ),
              h(
                "span",
                {
                  class: "operationA ml20",
                  on: {
                    click: () => {
                      this.rowData = params.row;
                      this.resetPwd = true;
                    },
                  },
                },
                "重置密码"
              ),
              //    h(
              //   "span", {
              //     class: "operationA ml20",
              //     on: {
              //       click: () => {
              //         window.open('http://localhost:8081/ ')
              //         // this.rowData = params.row

              //         // this.resetPwd = true
              //       },
              //     },
              //   },
              //   "进入企业"
              // )
            ]);
          },
        },
      ],
      props: {
        // checkStrictly: true,
        emitPath: false, //设为一维数组
        multiple: true,
        value: "id",
        label: "menuName",
        children: "children",
      },
      val: [],
      datetList: [],
      keyids: [],
      mlist: [],
      mlist2: [],
    };
  },
  mounted() {
    this.getTableData();
    this.getCarType();
    this.getMenuAll();
    this.getIncrementsAll();
    this.businessService();
    this.DateService();
  },

  methods: {
    checkChange(item, nodes) {
      // this.checkSt = false;
      // console.log(this.$refs.resourcesMenuRef);
      this.jurisdictionIdList = nodes.checkedKeys;
    },
    // change事件
    handleCheckedCitiesChange(val) {
      if (this.form.carTypeIds.length > val.length) {
        let diff = this.form.carTypeIds.filter(
          (v) => !val.find((item) => item === v)
        );
        this.mlist = diff;
      }
      for (const idx of val) {
        for (const v of this.carTypeList) {
          if (idx === v.businessId) {
            if (this.form.carTypeIds.indexOf(v.id) === -1) {
              this.form.carTypeIds.push(v.id);
              let ids = "";
              val.forEach((e) => {
                let obj = {};
                obj = this.menuList.find((item) => {
                  return item.id == e;
                });
                if (typeof obj != "undefined") {
                  obj.children.forEach((chilIds) => {
                    ids += chilIds.id + ",";
                  });
                }
              });
              console.log(ids);
              ids = ids.substr(0, ids.length - 1);
              if (this.form.carTypeIds.length > this.val.length) {
                this.val = this.form.businessServiceIds;
                this.form.menuIds = [
                  ...this.form.businessServiceIds,
                  ...ids.split(","),
                ];
              }
              this.form.menuIds = [
                ...this.form.businessServiceIds,
                ...ids.split(","),
              ];
              break;
            }
          }
        }
      }
      if (this.form.carTypeIds.length == 0) {
        this.form.businessServiceIds = [];
        this.dbShow = false;
        this.form.dataService = [];
      } else {
        this.form.dataService = this.dtList;
        this.dbShow = true;
      }
    },
    handleCheckedCitiesChange2(val) {
      //勾选关联
      if (this.form.carTypeIds.length == 0) {
        this.form.businessServiceIds = [];
        this.dbShow = false;
        this.form.dataService = [];
      } else {
        for (const idx of val) {
          for (const v of this.serviceList) {
            if (idx === v.id) {
              if (
                this.form.businessServiceIds.indexOf(v.id) === -1 &&
                this.mlist.indexOf(idx) === -1
              ) {
                this.form.businessServiceIds.push(v.id);
                break;
              } else {
                let diff2 = this.form.businessServiceIds.filter((v) =>
                  val.find((item) => item === v)
                );
                this.form.businessServiceIds = diff2;
              }
            }
          }
        }
        this.form.dataService = this.dtList;
        this.dbShow = true;
      }

      let ids = "";
      let ids2 = "";
      val.forEach((e) => {
        let obj = {};
        console.log(e);
        obj = this.menuList.find((item) => {
          return item.id == e;
        });

        if (typeof obj != "undefined") {
          obj.children.forEach((chilIds) => {
            ids += chilIds.id + ",";
          });
        }
      });
      this.form.dataService.forEach((e) => {
        let obj2 = {};
        obj2 = this.menuList.find((item) => {
          return item.id == e;
        });
        if (typeof obj2 != "undefined") {
          obj2.children.forEach((chilIds) => {
            ids2 += chilIds.id + ",";
          });
        }
      });
      if (this.form.dataService.length) {
        this.form.menuIds = [
          ...this.form.carTypeIds,
          ...ids.split(","),
          ...this.form.dataService,
          ...ids2.split(","),
          "9a96b1d58fd34029b1a2998dec1adcdf",
          "a04b37e827c047bd9256f65ee908ea21",
          "b869798c8c71475dad32d194a1f3fc46",
        ];
      } else {
        this.form.menuIds = [
          ...this.form.carTypeIds,
          ...ids.split(","),
          ...this.form.dataService,
          ...ids2.split(","),
        ];
      }
      ids = ids.substr(0, ids.length - 1);

      console.log(this.form.menuIds);
    },
    async companyMenu() {
      let { success, data, message } = await _UpdateCompanyMenu({
        companyId: this.rowData.id,
        menuIds: this.jurisdictionIdList.join(","),
      });
      if (success) {
        this.$message.success("设置成功");
        this.jurisdiction = false;
        this.reset();
      } else {
        this.$message.error(message);
      }
    },
    //重置密码
    async resetPwdConfig() {
      let { success, data, message } = await _UpdateUserCompanyPasswordCenter({
        companyId: this.rowData.id,
      });
      if (success) {
        this.resetPwd = false;
        this.$message.success("设置成功");
        this.reset();
      } else {
        this.$message.error(message);
      }
    },
    //关闭弹框
    reginsEnterShow() {
      this.isAdd = true;
      this.registerQy = true;
      this.resetForm();
    },
    drawClose() {
      // this.$refs.form.clearValidate();
      this.$refs.form.resetFields();
      this.dbShow = false
      this.hintShow = true
    },
    cascaderChange(value) {
      console.log(value);
      this.val = value;
      var a = [];
      if (value.length > 0) {
        for (
          var i = 0;
          i < this.$refs.myCascader.getCheckedNodes().length;
          i++
        ) {
          if (this.$refs.myCascader.getCheckedNodes()[i].level == 1) {
            a.push(this.$refs.myCascader.getCheckedNodes()[i].value);
          }
        }
      }
      this.form.carTypeIds = a;
      this.form.businessServiceIds = a;
      console.log(value, this.form.menuIds);
    },
    resetForm() {
      this.form = {
        securityCode: "", //企业安全码
        companyName: "", //企业名称
        companyAddress: "", //企业地址
        legalPerson: "", //法人代表
        phone: "", // 联系电话
        businessNature: "", // 经营范围
        carTypeIds: [], // 已购车型
        businessServiceIds: [], //业务服务包
        incrementServiceIds: [], // 增值服务包
        dataService: [],
        authAccount: "", // 授权账号
        menuIds: [], //授权菜单
        authStatus: "1", //状态
        parentId: "000000",
      };
    },
    // 改变授权状态
    async changeStatus(companyId, authStatus) {
      let { success, data, message } = await _UpdateUserCompanyAuth({
        companyId,
        authStatus,
      });
      if (success) {
        this.$message.success("修改成功");
        this.getTableData();
      } else {
        this.$message.error(message);
      }
    },
    // 删除
    async delConfig() {
      let { success, message, data } = await _DeleteCompany({
        companyId: this.rowData.id,
      });
      if (success) {
        this.$message.success("删除成功");
        this.getTableData();
        this.delDia = false;
      } else {
        // this.getTableData();
        // this.$message.success(message);
      }
    },
    confirmCancellation() {
      this.$refs.form.validate((valid) => {
        console.log(valid);
        if (valid) {
          this.isAdd ? this.enterpriseAdd() : this.enterpriseUpdate();
        }else{
          this.hintShow = false
        }
      });
    },
    // 注册企业
    enterpriseAdd() {
      this.isLoading = true;
      _CheckSecurityCode({
        securityCode: this.form.securityCode,
      }).then((res) => {
        if (!res) {
          this.isLoading = false;
        }
        if (res.success) {
          this.form.carTypeIds =
            this.form.carTypeIds.length > 0
              ? this.form.carTypeIds.join(",")
              : "";
          this.form.businessServiceIds =
            this.form.businessServiceIds.length > 0
              ? this.form.businessServiceIds.join(",")
              : "";
          this.form.dataService =
            this.form.dataService.length > 0
              ? this.form.dataService.join(",")
              : "";

          this.form.menuIds = Array.from(new Set(this.form.menuIds.flat()));
          this.form.incrementServiceIds =
            this.form.incrementServiceIds.length > 0
              ? this.form.incrementServiceIds.join(",")
              : "";

          _AddCompany(this.form).then((res) => {
            if (res.success) {
              this.$message.success("注册成功");
              this.registerQy = false;
              this.getTableData();
              this.isLoading = false;
            } else {
              this.$message.error(res.message);
              this.form.carTypeIds = [...this.form.carTypeIds.split(",")];
              this.form.businessServiceIds = [
                ...this.form.businessServiceIds.split(","),
              ];
              this.form.incrementServiceIds = [
                ...this.form.incrementServiceIds.split(","),
              ];
              this.form.dataService = [...this.form.dataService.split(",")];
              this.isLoading = false;
            }
          });
        } else {
          this.$message.error(res.message);
          this.form.carTypeIds = [...this.form.carTypeIds.split(",")];
          this.form.businessServiceIds = [
            ...this.form.businessServiceIds.split(","),
          ];
          this.form.incrementServiceIds = [
            ...this.form.incrementServiceIds.split(","),
          ];
          this.form.dataService = [...this.form.dataService.split(",")];
        }
      });
    },
    // 修改企业
    async enterpriseUpdate() {
      this.isLoading = true;
      let newObj = JSON.parse(JSON.stringify(this.form));
      newObj.id = this.rowData.id;
      newObj.carTypeIds = newObj.carTypeIds.join(",");
      newObj.businessServiceIds = newObj.businessServiceIds.join(",");
      newObj.dataService = newObj.dataService.join(",");
      newObj.menuIds = Array.from(new Set(newObj.menuIds.flat()));
      newObj.incrementServiceIds = newObj.incrementServiceIds.join(",");
      _UpdateCompany(newObj).then((res) => {
        if (!res) {
          this.isLoading = false;
        }
        if (res.success) {
          this.$message.success("修改成功");
          this.getTableData();
          this.registerQy = false;
          this.isLoading = false;
        } else {
          this.$message.error(message);
          this.form.carTypeIds = [...this.form.carTypeIds.split(",")];
          this.form.businessServiceIds = [
            ...this.form.businessServiceIds.split(","),
          ];
          this.form.incrementServiceIds = [
            ...this.form.incrementServiceIds.split(","),
          ];
          this.form.dataService = [...this.form.dataService.split(",")];
          this.isLoading = false;
        }
      });
    },
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.searchObj.page = pagination.page;
          this.searchObj.size = pagination.limit;
        }
      }
      let { success, message, data } = await _FindAllPageReg(this.searchObj);
      if (success == true) {
        this.tableData = data.list;
        this.total = data.total;
      }
      this.loading = false;
    },
    reset() {
      this.searchObj = {
        companyName: "",
        authAccount: "",
        phone: "",
        page: 1,
        size: 10,
      };
      this.getTableData();
    },
    // 获取所有车辆类型
    async getCarType() {
      let { success, data, message } = await _CarTypeAll();
      if (success) {
        this.carTypeList = data.list;
      }
    },
    //获取所有菜单
    async getMenuAll() {
      let { success, message, data } = await _GetAllMenu({
        type: 2,
      });
      if (success) {
        // this.menuList = this.recursionTree(data)
        this.menuList = data;
      }
    },
    //递归设置最后一个children为undefined
    // recursionTree(data) {
    //   for (var i = 0; i < data.length; i++) {
    //     if (data[i].children.length < 1) {
    //       // children若为空数组，则将children设为undefined
    //       data[i].children = undefined;
    //     } else {
    //       // children若不为空数组，则继续 递归调用 本方法
    //       this.recursionTree(data[i].children);
    //     }
    //   }
    //   return data;
    // },

    // 查询所有增值服务
    async getIncrementsAll() {
      let { message, data, success } = await _incrementsFindAll();

      if (success) {
        this.incrementList = data.list;
      }
    },

    //查询全部业务服务包
    async businessService() {
      let { success, data, message } = await _FindAllBusinessService();
      if (success) {
        this.serviceList = data.list;
      }
    },
    //查询全部数据服务包
    async DateService() {
      let { message, data, success } = await _FindAllDateService();

      if (success) {
        this.datetList = data.list;
        for (var a = 0; a < this.datetList.length; a++) {
          this.dtList.push(this.datetList[a].id);
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.example{
  color:#B8B2B2;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}
</style>
